import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';
import type { ExtractArrayType } from '@zento-lib/util/types';

import { fetchUsp, FetchUspQuery } from './operations.graphql';

type DisplayOn = 'AllPages' | 'Product' | 'Home' | 'Checkout' | 'Cart';

@Store
export class USPStore extends BaseStore {
  /**
   * GDPR categories types
   */
  @Field
  private uspsData: FetchUspQuery['usps'];

  /**
   * Fetch Usp
   */
  public async fetchUsp() {
    let usps: FetchUspQuery = { usps: [] };

    try {
      usps = await this.dataSources.graphQl.queue(fetchUsp);
    } catch (e) {
      console.error('USP Store (fetchUsp): ', e);
    }

    this.uspsData = usps.usps;

    return this.uspsData;
  }

  /**
   * All Pages Usp getter
   */
  public get uspsAllPages() {
    return this.uspsData
      ? this.uspsData.filter((usp) => usp.display_on === 'AllPages' && usp.store === currentStoreView().storeId)
      : [];
  }

  /**
   * Product Page Usp getter
   */
  public get uspsProduct() {
    return this.uspsData
      ? this.uspsData.filter((usp) => usp.display_on === 'Product' && usp.store === currentStoreView().storeId)
      : [];
  }

  /**
   * Cart Usp getter
   */
  public get uspsCart() {
    return this.uspsData
      ? this.uspsData.filter((usp) => usp.display_on === 'Cart' && usp.store === currentStoreView().storeId)
      : [];
  }

  /**
   * Checkout Page Usp getter
   */
  public get uspsCheckout() {
    return this.uspsData
      ? this.uspsData.filter((usp) => usp.display_on === 'Checkout' && usp.store === currentStoreView().storeId)
      : [];
  }

  /**
   * Retrieve specific usp by it's display_on property
   */
  public getUspByDisplayOn(displayOn: DisplayOn): ExtractArrayType<FetchUspQuery['usps']> | null {
    return (
      this.uspsData.find((usp) => usp.display_on === displayOn && usp.store === currentStoreView().storeId) || null
    );
  }

  /**
   * Retrieve specific usp by it's identifier
   */
  public getUspByIdentifier(identifier: number): ExtractArrayType<FetchUspQuery['usps']> | null {
    return this.uspsData.find((usp) => usp.item_id === identifier && usp.store === currentStoreView().storeId) || null;
  }
}
